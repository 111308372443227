// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import PerformanceWhatsappFormModal from './FormModal';
import PerformanceWhatsappForm from './Form';

export default class PerformanceWhatsappFormApp extends Component {
  render() {
    return (
      <PerformanceWhatsappFormModal
        openModalTriggerSelector={this.props.openModalTriggerSelector}
      >
        <PerformanceWhatsappForm
          channel={this.props.channel}
          product={this.props.product}
          category={this.props.category}
          brand={this.props.brand}
          link={this.props.link}
          formId={this.props.formId}
          buttonLabel={this.props.buttonLabel}
          showCpf={this.props.showCpf}
          showUnits={this.props.showUnits}
          showContactOptions={this.props.showContactOptions}
          showEmail={this.props.showEmail}
          showDataPermissions={this.props.showDataPermissions}
          dataPermissionsCustomText={this.props.dataPermissionsCustomText}
          linkPrivacyPolicy={this.props.linkPrivacyPolicy}
          units={this.props.units}
          unit={this.props.unit}
          hasModel={this.props.hasModel}
        />
      </PerformanceWhatsappFormModal>
    );
  }
}

PerformanceWhatsappFormApp.defaultProps = {
  openModalTriggerSelector: null,
  buttonLabel: 'Converse agora!',
  showContactOptions: true,
  unit: '',
  hasModel: false,
};

PerformanceWhatsappFormApp.propTypes = {
  openModalTriggerSelector: PropTypes.string,
  formId: PropTypes.string,
  buttonLabel: PropTypes.string,
  product: PropTypes.string,
  channel: PropTypes.string,
  category: PropTypes.string,
  brand: PropTypes.string,
  link: PropTypes.string,
  showCpf: PropTypes.bool,
  showUnits: PropTypes.bool,
  showContactOptions: PropTypes.bool,
  showEmail: PropTypes.bool,
  linkPrivacyPolicy: PropTypes.string,
  showDataPermissions: PropTypes.bool,
  dataPermissionsCustomText: PropTypes.string,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  unit: PropTypes.string,
  hasModel: PropTypes.bool,
};
