// eslint-disable-next-line no-unused-vars
import { h } from 'preact';
import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class Checkbox extends Component {
  render() {
    const { customClasses, name, value, label, handleChange, isChecked } =
      this.props;

    return (
      <div className={classNames('form__checkbox-field', customClasses)}>
        <label className="form__checkbox-field-label">
          <input
            className="form__checkbox-field-input"
            type="checkbox"
            value={value}
            name={name}
            onChange={handleChange}
            checked={isChecked}
          />
          <span class='form__checkbox-field-label-title'>
            {label}
          </span>
          <span className="custom-checkbox" />
        </label>
      </div>
    );
  }
}

Checkbox.propTypes = {
  customClasses: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.bool,
  isChecked: PropTypes.bool,
  handleChange: PropTypes.func,
};
