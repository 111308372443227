import { Component } from 'react';
import PropTypes from 'prop-types';
import Card from '../../Cards/NewVehiclesFiatV2';
import PlaceholderCard from '../../Cards/Placeholder';
import { autoServiceApiV2 } from '../../../services/autoServiceApiV2';

export default class NewVehiclesLazyFiatV2Carousel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newVehicles: [],
      isFetching: true,
    };

    this.initCarousel = this.initCarousel.bind(this);
    this.fetchNewVehicles = this.fetchNewVehicles.bind(this);
  }

  componentDidMount() {
    this.initCarousel();
    this.fetchNewVehicles();
  }

  componentDidUpdate() {
    this.initCarousel();
  }

  // eslint-disable-next-line consistent-return
  getSideProfileImage(vehicle) {
    if (!vehicle) return null;

    if (vehicle.side_profile_image) return vehicle.side_profile_image;
    if (vehicle.profile_image) return vehicle.profile_image;
  }

  async fetchNewVehicles() {
    const requestParams = [
      { active: true },
      { per_page: 8 },
      { page: 1 },
      { sort: 'ordination,id' },
    ];
    const result = await autoServiceApiV2.getClones(requestParams);
    const newVehiclesList = result ? result.entries : this.state.newVehicles;

    this.setState(() => ({
      newVehicles: newVehiclesList,
      isFetching: false,
    }));
  }

  initCarousel() {
    const carousel = window.makeCarousel(this.carouselRef, {
      perPage: 4,
      perMove: 1,
      pagination: false,
      lazyLoad: 'nearby',
      preloadPages: 0,
      gap: 0,
      breakpoints: {
        400: {
          perPage: 1,
        },
        560: {
          perPage: 1,
        },
        900: {
          perPage: 3,
        },
        1180: {
          perPage: 4,
        },
      },
    });

    return carousel;
  }

  render() {
    const { newVehicles, isFetching } = this.state;
    const { cardLink, abarthScorpionIcon } = this.props;

    return (
      <div
        className="new-vehicles-lazy__carousel splide"
        data-is-fetching={isFetching}
        ref={node => {
          this.carouselRef = node;
        }}
      >
        <div className="splide__track container">
          <div className="splide__list new-vehicles-lazy__carousel-items-wrapper">
            {isFetching &&
              newVehicles.length === 0 &&
              [1, 2, 3, 4].map(item => (
                <div
                  className="new-vehicles-lazy__carousel-placeholder-item carousel__item splide__slide"
                  key={item}
                >
                  <PlaceholderCard
                    customClasses="new-vehicles-lazy__placeholder-card"
                    showBody={false}
                  />
                </div>
              ))}
            {!isFetching &&
              newVehicles.length > 0 &&
              newVehicles.map(vehicle => (
                <div
                  className="new-vehicles-lazy__carousel-item splide__slide"
                  key={vehicle.slug}
                >
                  <Card
                    title={vehicle.title}
                    image={
                      this.getSideProfileImage(vehicle) || vehicle.profile_image
                    }
                    link={`${cardLink}/${vehicle.slug}`}
                    width={this.getSideProfileImage(vehicle) ? 236 : 176}
                    height={this.getSideProfileImage(vehicle) ? 105 : 123.63}
                    customClasses={
                      vehicle.side_profile_image
                        ? 'new-vehicle-card__image--mt'
                        : ''
                    }
                    abarthScorpionIcon={abarthScorpionIcon}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

NewVehiclesLazyFiatV2Carousel.defaultProps = {
  cardLink: '/novos',
  abarthScorpionIcon: '',
};

NewVehiclesLazyFiatV2Carousel.propTypes = {
  cardLink: PropTypes.string,
  abarthScorpionIcon: PropTypes.string,
};
