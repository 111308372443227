import getUTM from '%/utils/utm';
import { current_unit_slug } from '%/utils/current_unit';

const getStateData = (state, props, handleDynamicData) => {
  const stateData = {
    name: state.name,
    email: state.email,
    phone: state.phone,
    unit: state.unit || current_unit_slug(),
    mailing: state.mailing,
    phoning: state.phoning,
    whatsapping: state.whatsapping,
    version: state.selectedVariant,
    'veiculo-na-troca': state['veiculo-na-troca'],
  };

  if (props.showCpf) {
    stateData.cpf = state.cpf;
  }

  if (props.showDataPermissions) {
    stateData.data_permissions = state.dataPermissions;
  }

  if (typeof handleDynamicData !== 'undefined') {
    const dynamicData = handleDynamicData(state);
    return { ...stateData, ...dynamicData };
  }

  return stateData;
};

const getPropsData = props => {
  const propsData = {
    product: props.product,
    bait: -5,
    channel: props.channel,
    category: props.category,
    brand: props.brand,
    utmz: getUTM(),
  };

  if (props.cloneId) {
    propsData.product_id = props.cloneId;
  }

  if (props.modelYear) {
    propsData.modelYear = props.modelYear;
  }

  if (props.productCode) {
    propsData.productCode = props.productCode;
  }

  if (props.fcaOfferId) {
    propsData.fca_offer_id = props.fcaOfferId;
  }

  return propsData;
};

export const getPayload = (state, props, handleDynamicData) => {
  const propsData = getPropsData(props);
  const stateData = getStateData(state, props, handleDynamicData);

  const payload = { ...stateData, ...propsData };

  return payload;
};
