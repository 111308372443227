import NewVehiclesFiatV2LazyCarousel from '../../auto/components/Carousels/FiatV2/NewVehiclesLazy';
import OffersNewsFiatV2LazyCarousel from '../../auto/components/Carousels/FiatV2/OffersNewsLazy';

(() => {
  // Adicione aqui chaves:valor com os componentes que serão atribuídos ao escopo
  // global. Não passe eles pelo hyperscript (h) aqui pois a página que os usa
  // é quem deve compilá-lo com os pros.
  const components = {
    NewVehiclesFiatV2LazyCarousel,
    OffersNewsFiatV2LazyCarousel,
  };

  Object.keys(components).forEach(c => {
    window[c] = components[c];
  });
})();
