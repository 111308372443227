// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import propTypes from 'prop-types';

export default class PerformanceWhatsappFormModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: true,
    };

    this.handleModalToggle = this.handleModalToggle.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    const openModalTriggerElement = document.querySelector(
      this.props.openModalTriggerSelector,
    );

    if (!openModalTriggerElement) return;

    openModalTriggerElement.addEventListener('click', this.handleModalToggle);
  }

  handleModalToggle(value) {
    const modalNewState =
      typeof value === 'boolean' ? value : !this.state.isOpen;

    this.setState(oldState => ({
      ...oldState,
      isOpen: modalNewState,
    }));
  }

  handleClickOutside(event) {
    if (this.modalContentRef && !this.modalContentRef.contains(event.target)) {
      this.handleModalToggle(false);
    }
  }

  render() {
    const { children } = this.props;

    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        className="custom-modal"
        data-open={this.state.isOpen}
        onClick={this.handleClickOutside}
        onKeyDown={this.handleClickOutside}
      >
        <div className="custom-modal__overlay">
          <div
            className="custom-modal__content"
            ref={node => {
              this.modalContentRef = node;
            }}
          >
            <button
              className="custom-modal__button-close"
              onClick={this.handleModalToggle}
              label="Fechar"
              type="button"
            >
              <i className="icon icon-close" />
            </button>
            {children}
          </div>
        </div>
      </div>
    );
  }
}

PerformanceWhatsappFormModal.defaultProps = {
  children: null,
  openModalTriggerSelector: null,
};

PerformanceWhatsappFormModal.propTypes = {
  openModalTriggerSelector: propTypes.string,
  children: propTypes.element,
};
