import { Component } from 'preact';
import PropTypes from 'prop-types';

const DAY_TIME = 1000 * 60 * 60 * 24;
const HOUR_TIME = 1000 * 60 * 60;
const MINUTES_TIME = 1000 * 60;
const SECONDS_TIME = 1000;

class TimeMissingFiatV2 extends Component {
  constructor(props) {
    super(props);

    const { date } = this.props;

    this.nowTime = Date.now();
    this.dateTime = date.getTime();
  }

  componentDidMount() {
    // definindo setInterval apenas quando faltar menos de 1 hora para o término da oferta
    if (this.getHours() > 0) return;

    window.setInterval(() => {
      this.nowTime += 1000;
      this.forceUpdate();

      // se zerar o contador
      if (this.nowTime >= this.dateTime) {
        window.clearInterval(this);
      }
    }, 1000);
  }

  getDays() {
    let days = (this.dateTime - this.nowTime) / DAY_TIME;
    days = Math.floor(days);

    if (days < 0) {
      days = 0;
    }

    return days;
  }

  getHours() {
    let hours = (this.dateTime - this.nowTime) / HOUR_TIME;
    hours = Math.floor(hours);

    if (hours < 0) {
      hours = 0;
    }

    return hours;
  }

  getMinutes() {
    const diff = this.dateTime - this.nowTime;
    let minutes = diff / MINUTES_TIME;
    minutes -= this.getHours() * 60;
    minutes = Math.floor(minutes);

    if (minutes < 0) {
      minutes = 0;
    }

    return minutes;
  }

  getSeconds() {
    const diff = this.dateTime - this.nowTime;
    let seconds = diff / SECONDS_TIME;
    seconds -= this.getHours() * 60 * 60;
    seconds -= this.getMinutes() * 60;
    seconds = Math.floor(seconds);

    if (seconds < 0) {
      seconds = 0;
    }

    return seconds;
  }

  renderTime() {
    if (this.getDays() >= 1) {
      return [this.getDays(), 'dia'];
    }
    if (this.getHours() > 0) {
      return [this.getHours(), 'hora'];
    }
    if (this.getMinutes() > 0) {
      return [this.getMinutes(), 'minuto'];
    }
    return [this.getSeconds(), 'segundo'];
  }

  render() {
    const [time, timeUnit] = this.renderTime();
    const textTimeUnit = time > 1 ? `${timeUnit}s` : timeUnit;

    return (
      <div className="offers-fca-card__time-missing">
        <p>Expira em</p>
        <span className="icon icon-timer" data-time={time} />
        <p>{textTimeUnit}</p>
      </div>
    );
  }
}

TimeMissingFiatV2.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
};

export default TimeMissingFiatV2;
