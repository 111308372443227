// eslint-disable-next-line no-unused-vars
import { h } from 'preact';
import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class NewVehiclesFiatV2Card extends Component {
  render() {
    const {
      title,
      image,
      link,
      width,
      height,
      customClasses,
      abarthScorpionIcon,
    } = this.props;

    return (
      <div className="new-vehicle-card">
        <a href={link}>
          <div className="new-vehicle-card__background" />
          <h3 className="new-vehicle-card__title">{title}</h3>
          {title.toLowerCase().includes('abarth') && (
            <img
              className="new-vehicle-card__icon-abarth"
              src={abarthScorpionIcon}
              alt="Selo do Abarth"
              width="45"
              height="50"
            />
          )}
          <img
            className={`${classNames('news-card__image')} ${customClasses}`}
            src={image}
            alt={`Imagem do ${title}`}
            width={width}
            height={height}
          />
        </a>
      </div>
    );
  }
}

NewVehiclesFiatV2Card.defaultProps = {
  title: '',
  image: '',
  link: '',
  abarthScorpionIcon: '',
};

NewVehiclesFiatV2Card.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  link: PropTypes.string,
  abarthScorpionIcon: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  customClasses: PropTypes.string,
};
