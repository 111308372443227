import DDMOffers from '../../auto/stores/DDMOffers';

(() => {
  // Adicione chaves:valor com os stores que serão atribuídos ao escopo global
  const store = {
    DDMOffers,
  };
  window.store = Object.assign(window.store || {}, {});
  // eslint-disable-next-line no-return-assign
  Object.keys(store).forEach(c => (window.store[c] = store[c]()));
})();
