// eslint-disable-next-line no-unused-vars
import { h } from 'preact';
import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class Select extends Component {
  render() {
    const { customClasses, name, options, placeholder, label, handleChange } =
      this.props;

    return (
      <div className={classNames('form__select-field', customClasses)}>
        {label && <label className="form__select-field-label">{label}</label>}
        <select
          className="form__select-field-input"
          name={name}
          onChange={handleChange}
        >
          {placeholder && (
            <option value="" selected disabled>
              {placeholder}
            </option>
          )}
          {options &&
            options.map(option => (
              <option
                className="form__select-field-option"
                value={option.value}
                selected={option.selected}
              >
                {option.label}
              </option>
            ))}
        </select>
      </div>
    );
  }
}

Select.propTypes = {
  customClasses: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(),
  handleChange: PropTypes.func,
};
