import { Component } from 'react';
import PropTypes from 'prop-types';
import Card from '../../Cards/NewOffersFiatV2';

export default class OffersNew extends Component {
  constructor(props) {
    super(props);
    this.initCarousel = this.initCarousel.bind(this);
    this.cardProps = this.cardProps.bind(this);
  }

  componentDidMount() {
    this.initCarousel();
  }

  componentDidUpdate() {
    this.initCarousel();
  }

  initCarousel() {
    const carousel = window.makeCarousel(this.carouselRef, {
      perPage: 4,
      perMove: 4,
      gap: 16,
      pagination: true,
      lazyLoad: 'nearby',
      arrows: true,
      preloadPages: 0,
      drag: false,
      breakpoints: {
        768: {
          perPage: 1,
          perMove: 1,
          fixedWidth: 272,
        },
        990: {
          perPage: 2,
          perMove: 2,
          gap: 24,
          padding: 24,
          pagination: false,
          arrows: false,
          drag: true,
        },
        1200: {
          perPage: 3,
          perMove: 3,
        },
      },
    });

    carousel.on('move', () => {
      if (document.activeElement !== document.body) {
        document.activeElement.blur();
      }
    });

    return carousel;
  }

  cardProps(offer) {
    const { thumbs, base_type, slug, seal_list } = offer;
    const { channelUrl } = this.props;

    const image = thumbs.middle_image;
    const link = window.helpers.cardDDMOffersLink(base_type, slug, channelUrl);

    const sealListLowercased = seal_list.map(seal => seal.toLowerCase());
    const showBadge =
      base_type.includes('Hub') || base_type.includes('Marketplace');

    return {
      title: offer.name,
      subtitle: offer.model,
      image,
      link,
      sealList: sealListLowercased,
      offer: offer.item_offer,
      showBadge,
      badge: offer.category,
      showExpiredAt: !!offer.expired_at,
      expiredAt: offer.expired_at,
      baseType: base_type,
      offerType: offer.offer_type,
    };
  }

  render() {
    const { offers } = this.props;

    return (
      <div
        className=" splide"
        ref={node => {
          this.carouselRef = node;
        }}
      >
        <div className="splide__track container">
          <div className="splide__list">
            {offers.map(offer => (
              <div className="splide__slide" key={offer.slug}>
                <Card {...this.cardProps(offer)} />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

OffersNew.defaultProps = {
  channelUrl: undefined,
  offers: [],
};

OffersNew.propTypes = {
  channelUrl: PropTypes.string,
  offers: PropTypes.arrayOf(Object),
};
