import { getMask } from '../../customForm/mask/getMask';

export const mask = (field, maskConfig, callback) => {
  const fieldMask = getMask(maskConfig.mask);

  const handleFieldInput = e => {
    const target = e.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;

    if (fieldMask) {
      value = fieldMask(value);
      target.value = value;
    }

    if (!target.dataset.initialized) {
      target.setAttribute('data-initialized', true);
      return;
    }

    callback(value);
  };

  field.addEventListener('input', handleFieldInput);
};
