export const handlePopupClose = () => {
  const popupEl = document.querySelector('.channel-pop-up');
  if (!popupEl) return;

  const popupCloseActionEls = Array.from(
    document.querySelectorAll('.channel-pop-up__action-close')
  );

  popupCloseActionEls.forEach((closeAction) => {
    closeAction.addEventListener('click', () => {
      const pathPopup = `/${document.location.pathname.split('/')[1]}`;
      const expirationData = new Date();
      expirationData.setHours(expirationData.getHours() + 3); // 3 dias de validade
      document.cookie = `channel_pop_popup_close=true; expires=${expirationData.toUTCString()}; path=${pathPopup}`;

      const currentDate = new Date();
      // eslint-disable-next-line max-len
      document.cookie = `channel_pop_up_closed_at=${currentDate.toISOString()}; expires=${expirationData.toUTCString()}; path=${pathPopup}`;

      popupEl.remove();
    });
  });
};
