// eslint-disable-next-line no-unused-vars
import { h } from 'preact';
import { Component } from 'react';
import PropTypes from 'prop-types';
import FieldGroup from '../../FieldGroup';
import Checkbox from '../Checkbox';

export default class ContactFieldGroup extends Component {
  render() {
    const { handleChange, mailing, whatsapping, phoning } = this.props;

    return (
      <FieldGroup customClasses="contact-field-group">
        <legend className="contact-field-group__label">
          Quero receber contato por:
        </legend>
        <div className="contact-field-group__wrapper">
          <Checkbox
            label="E-mail"
            name="mailing"
            value={mailing}
            isChecked={mailing}
            handleChange={handleChange}
          />
          <Checkbox
            label="Whatsapp"
            name="whatsapping"
            value={whatsapping}
            isChecked={whatsapping}
            handleChange={handleChange}
          />
          <Checkbox
            label="Telefone"
            name="phoning"
            value={phoning}
            isChecked={phoning}
            handleChange={handleChange}
          />
        </div>
      </FieldGroup>
    );
  }
}

ContactFieldGroup.propTypes = {
  handleChange: PropTypes.func,
  mailing: PropTypes.bool,
  whatsapping: PropTypes.bool,
  phoning: PropTypes.bool,
};
