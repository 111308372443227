export const initSectionWhenItIsVisible = (
  targetElement,
  callback,
  threshold = 0.1,
) => {
  const observerConfig = {
    threshold,
  };

  const observerCallback = (entries, observer) => {
    if (entries[0].isIntersecting) {
      callback();
      observer.disconnect();
    }
  };

  const sectionObserver = new IntersectionObserver(
    observerCallback,
    observerConfig,
  );

  sectionObserver.observe(targetElement);
};
