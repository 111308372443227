import { Component } from 'preact';
import WhatsAppFormWithModal from '../PerformanceWhatsappForm/FormApp';

export default class FiatV2WhatsappForm extends Component {
  render() {
    const props = this.props;

    return <WhatsAppFormWithModal {...props} />;
  }
}

FiatV2WhatsappForm.defaultProps = {
  showEmail: false,
  showDataPermissions: false,
};
